// Our main CSS
import '../css/app.css'



import { createApp } from 'vue'


if (document.getElementById('marquee')) {
    import('./marquee').then((module) => {
        module.default()
    })
}

if (document.querySelector('.js-numbers')) {
    import('./numbers').then((module) => {
        module.default()
    })
}
var workServices = document.getElementsByClassName('work-services');

if (workServices && workServices.length > 0) {
    import('./workServices').then((module) => {
        module.default()
    })
}

var timelineSlides = document.getElementsByClassName('timeline-slides');

if (timelineSlides && timelineSlides.length > 0) {
    import('./timelineSlides').then((module) => {
        module.default()
    })
}


const menu = createApp({

    data: () => ({
        active: null,
        subMenuActive: null,
        langMenu: null,
        activeSearch: null,
        mobileMenuActive: false,
    }),

    watch: {
        active: {
            handler(newValue) {
                if (newValue === null) {
                    return document.body.classList.remove('menu-open', 'overlay');
                }
                return document.body.classList.add('menu-open')
            },
            immediate: true
        }
    },

    methods: {
        toggle(name) {
            if (this.active === name) {
                this.subMenuActive = null
                return this.active = null
            }

            this.active = name
        },
        subMenuToggle(name) {
            if (this.subMenuActive === name) {
                return this.subMenuActive = null
            }

            this.subMenuActive = name
        },
        subMenuShow(name) {
            this.subMenuActive = name
        },
        subMenuHide() {
            this.subMenuActive = null
        },
        toggleLang() {
            if (this.langMenu === 'lang') {
                return this.langMenu = null
            }

            this.langMenu = 'lang'
        },
        toggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive
        },
        closeAllMenus() {
            this.subMenuActive = null
            this.active = null
            this.langMenu = null
        },
        toggleSearch() {
            if (this.activeSearch === 'search') {
                return this.activeSearch = null
            }

            this.activeSearch = 'search'
        },
        handleScroll() {
            const firstNav = this.$refs.firstNav;
            const secondNav = this.$refs.secondNav;
            const mobileNav = this.$refs.mobileNav;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > 800) {
                firstNav.style.transform = 'translateY(-130%)'; // Hide the first navigation
                secondNav.style.transform = 'translateY(0%)';    // Show the second navigation
            } else {
                firstNav.style.transform = 'translateY(0%)'; // Hide the first navigation
                secondNav.style.transform = 'translateY(-130%)';    // Show the second navigation
            }

            if (scrollTop > 300) {
                mobileNav.classList.add('mobile-nav-scrolled');
            }
            else {
                mobileNav.classList.remove('mobile-nav-scrolled');
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
})

menu.mount('#main-menu')